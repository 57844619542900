import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useObjectsList() {
  // Use toast
  const toast = useToast()

  const refObjectListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalObjects = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refObjectListTable.value ? refObjectListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalObjects.value,
    }
  })

  const refetchData = () => {
    refObjectListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchObjects = (ctx, callback) => {
    store
      .dispatch('app-object/fetchObjects', {
        role: roleFilter.value,
        status: statusFilter.value,
        _limit: perPage.value,
        _start: (currentPage.value - 1) * perPage.value,
        _sort: `${sortBy.value}:${isSortDirDesc.value ? 'DESC' : 'ASC'}`,
      })
      .then(response => {
        const objects = response.data
        callback(objects)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching objects list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    store
      .dispatch('app-object/fetchObjectsCount', {
        role: roleFilter.value,
        status: statusFilter.value,
        _limit: perPage.value,
        _start: (currentPage.value - 1) * perPage.value,
        _sort: `${sortBy.value}:${isSortDirDesc.value ? 'DESC' : 'ASC'}`,
      })
      .then(response => {
        totalObjects.value = response.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching objects list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveObjectRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveObjectRoleIcon = role => {
    if (role === 'subscriber') return 'ObjectIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'ObjectIcon'
  }

  const resolveObjectStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchObjects,
    tableColumns,
    perPage,
    currentPage,
    totalObjects,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refObjectListTable,

    resolveObjectRoleVariant,
    resolveObjectRoleIcon,
    resolveObjectStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
